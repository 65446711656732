import { ClickAwayListener, Menu } from "@mui/material";
import { colorPalette } from "gx-npm-common-styles";
import { getAsyncRequest, useUserState } from "gx-npm-lib";
import { publish, updateStateSubject } from "gx-npm-messenger-util";
import { IconButton, TypographyComponent, useFeatureFlag, UsernameDisplay } from "gx-npm-ui";
import { KeyboardEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import { GCOM_3718_GCOM_3740__removeProfileModalFromApp } from "../../../lib/feature-flags";
import FadeContainerComponent from "../../ui/fade-container.component";
import UserLogoutButtonIcon from "./user-logout-button.icon";
import styles from "./user-logout-section.styles.module.scss";

type UserLogoutSectionComponentProps = { isExpanded?: boolean };
const UserLogoutSectionComponent = ({ isExpanded = false }: UserLogoutSectionComponentProps) => {
  const { t } = useTranslation();
  const { hasUserState, firstName, lastName, profileComplete } = useUserState();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLMenuElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const isRemoveProfileModalFFOn = useFeatureFlag(GCOM_3718_GCOM_3740__removeProfileModalFromApp);

  useEffect(() => {
    if (!hasUserState || isRemoveProfileModalFFOn) {
      return;
    }
    updateStateSubject("PROFILE_COMPLETE_STATUS", { isProfileIncomplete: !profileComplete });
  }, [hasUserState, isRemoveProfileModalFFOn, profileComplete]);

  const handleLogout = async () => {
    setMenuOpen(false);
    const res = await getAsyncRequest("/api/v2/auth/logout");
    if (res.status === 200) {
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
      sessionStorage.clear();
      publish("USER_STATE_CLEAR");
      publish("USER_LOGGED_OUT");
      publish("WS_DISCONNECT");
      navigateToUrl("/login?logout=true");
    }
  };

  return (
    <div className={styles.itemContainer}>
      <UsernameDisplay
        rootClassName={styles.userAvatarContainer}
        name={`${firstName} ${lastName}`}
        showName={false}
        isUnknown={!hasUserState}
      />
      <FadeContainerComponent in={isExpanded}>
        <div className={styles.userNameLogoutContainer}>
          <TypographyComponent rootClassName={styles.userTextContainer} type={"p3"} boldness={"medium"} color={"white"}>
            {firstName}
          </TypographyComponent>
          <IconButton
            ariaLabel={t("user menu button")}
            hoverFill={colorPalette.basic.white.hex}
            hoverBackgroundColor={colorPalette.brand.gartnerBlue.hex}
            onClick={(e: MouseEvent<HTMLMenuElement> | KeyboardEvent<HTMLMenuElement>) => {
              setMenuAnchorEl(e.currentTarget);
              setMenuOpen(true);
            }}
          >
            <UserLogoutButtonIcon />
          </IconButton>
        </div>
      </FadeContainerComponent>
      <Menu
        open={menuOpen}
        disablePortal={true}
        anchorEl={menuAnchorEl}
        classes={{ paper: styles.muiMenuPaper }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <div>
          <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
            <button className={styles.logoutMenuItem} onClick={handleLogout}>
              <TypographyComponent type={"p3"} boldness={"medium"}>
                {t("Log out")}
              </TypographyComponent>
            </button>
          </ClickAwayListener>
        </div>
      </Menu>
    </div>
  );
};

export default UserLogoutSectionComponent;
