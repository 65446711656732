import classNames from "classnames";
import { createStateSubscription, destroyStateSubscription } from "gx-npm-messenger-util";
import { TypographyComponent } from "gx-npm-ui";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FadeContainerComponent from "../../ui/fade-container.component";
import CurrentEvalSectionIcon from "./current-eval-section.icon";
import styles from "./current-eval-section.styles.module.scss";

type CurrentEvalSectionComponentProps = {
  isExpanded?: boolean;
};

const CurrentEvalSectionComponent = ({ isExpanded = false }: CurrentEvalSectionComponentProps) => {
  const { t } = useTranslation();
  const [currentInitTitle, setCurrentInitTitle] = useState("");

  useEffect(() => {
    const currentInitSubscription = createStateSubscription(
      "CURRENT_INITIATIVE",
      (updatedState: { name?: string | null }) => {
        setCurrentInitTitle(updatedState?.name || "");
      }
    );
    return () => {
      destroyStateSubscription(currentInitSubscription);
    };
  }, []);

  return !currentInitTitle ? (
    <Fragment />
  ) : (
    <Fragment>
      <div>
        <div className={styles.divider} />
        <div className={styles.dividerLabelContainer}>
          <TypographyComponent color={"white"} type={"p4"} boldness={"medium"}>
            {t("Current")}
            <FadeContainerComponent in={isExpanded}>
              <span> {t("evaluation")}</span>
            </FadeContainerComponent>
          </TypographyComponent>
        </div>
      </div>
      <li className={styles.listItem}>
        <div aria-label={t("current evaluation")} tabIndex={-1} className={styles.itemButton}>
          {isExpanded ? (
            <div className={classNames(styles.itemButtonTextContainer)}>
              <FadeContainerComponent in={isExpanded}>
                <div>
                  <TypographyComponent color={"white"} rootClassName={styles.itemButtonText} type={"p2"}>
                    {currentInitTitle}
                  </TypographyComponent>
                </div>
              </FadeContainerComponent>
            </div>
          ) : (
            <div className={styles.itemButtonIconContainer}>
              <CurrentEvalSectionIcon />
            </div>
          )}
        </div>
      </li>
    </Fragment>
  );
};

export default CurrentEvalSectionComponent;
